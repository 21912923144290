import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS
// Optionally, if you want to use a different locale (e.g., for date format)
import ja from 'date-fns/locale/ja';
// Register the locale if you want to use a different one
registerLocale('ja', ja);

interface CustomDatePickerProps {
  value: Date | undefined;
  onChange: (date: Date) => void;
  customInputComponent?: React.ReactElement | null;
  [key: string]: any; // Rest parameter to accept any other props
  disabled?: boolean;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  onChange,
  customInputComponent,
  disabled,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    onChange(date);
  };
  return (
    <>
      {value ? (
        <DatePicker
          disabled={disabled}
          selected={selectedDate}
          onChange={handleDateChange}
          customInput={customInputComponent}
          locale="ja"
          {...rest} // Spread rest of the props
        />
      ) : (
        <DatePicker
          disabled={disabled}
          onChange={handleDateChange}
          customInput={customInputComponent}
          locale="ja"
          {...rest} // Spread rest of the props
        />
      )}
    </>
  );
};

export default CustomDatePicker;
