// @ts-ignore
import Template from './template.json';
import { FaDownload } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import ExportLink from 'components/common/ExportLink';

function ExportCSV() {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    let dataCSV = [];
    for (const asset of Template.data) {
      dataCSV.push({
        management_no: asset.management_no,
        purchased_date: asset.purchased_date,
        size: asset.size,
        manufacturer_serial_number: asset.manufacturer_serial_number,
        external_order_number: asset.external_order_number,
        compressed_output: asset.compressed_output,
        accounting_class_id: asset.accounting_class.id,
        accounting_class_name: asset.accounting_class.name,
        accounting_no: asset.accounting_no,
        leasing_divided_contract_company: asset.leasing_divided_contract_company,
        type_id: asset.type.id,
        type_name: asset.type.name,
        model_id: asset.model.id,
        model_name: asset.model.name,
        manufacturer_id: asset.manufacturer.id,
        manufacturer_name: asset.manufacturer.name,
        asset_classification_id: asset.classification.id,
        asset_classification_cd: asset.classification.cd,
        asset_classification_name: asset.classification.name,
        model_number: asset.model_number,
        mac_address: asset.mac_address,
        gas_specie: asset.gas_specie,
        power_supply: asset.power_supply,
        purchased_price: asset.purchased_price,
        purchased_method: asset.purchased_method,
        contract_expiry_date: asset.contract_expiry_date,
        current_use_id: asset.current_use?.id,
        current_use_name: asset.current_use?.name,
        current_status_id: asset.current_status?.id,
        current_status_name: asset.current_status?.name,
        current_installation_location: asset.current_installation_location,
        current_use_location: asset.current_use_location,
        current_possession_company_cd: asset.current_possession_company?.cd,
        current_possession_company_name: asset.current_possession_company?.name,
        current_possession_company_short_name: asset.current_possession_company?.short_name,
        current_possession_department_cd: asset.current_possession_department?.cd,
        current_possession_department_name: asset.current_possession_department?.name,
        current_possession_employee_cd: asset.current_possession_employee?.cd,
        current_possession_employee_name: asset.current_possession_employee?.username,
        current_storage_place_id: asset.current_storage_place?.id,
        current_storage_place_name: asset.current_storage_place?.name,
        restaurant_cd: asset.current_possession_department?.restaurant_cd,
      });
    }
    setData(dataCSV);
  }, []);

  const headers = [
    { header: '管理番号', key: 'management_no' },
    { header: '購入日', key: 'purchased_date' },
    { header: '寸法', key: 'size' },
    { header: 'メーカー製造番号', key: 'manufacturer_serial_number' },
    { header: '社外注文番号', key: 'external_order_number' },
    { header: '圧縮出力', key: 'compressed_output' },
    { header: '会計クラスID', key: 'accounting_class_id' },
    { header: '会計区分', key: 'accounting_class_name' },
    { header: '会計番号', key: 'accounting_no' },
    {
      header: 'リース／分割契約会社',
      key: 'leasing_divided_contract_company',
    },
    { header: 'タイプID', key: 'type_id' },
    { header: '型名', key: 'type_name' },
    { header: 'モデルID', key: 'model_id' },
    { header: '機種名', key: 'model_name' },
    { header: 'メーカーID', key: 'manufacturer_id' },
    { header: 'メーカー名', key: 'manufacturer_name' },
    { header: '資産分類ID', key: 'asset_classification_id' },
    { header: '資産分類コード', key: 'asset_classification_cd' },
    { header: '資産種別', key: 'asset_classification_name' },
    { header: '型番', key: 'model_number' },
    { header: 'MACアドレス', key: 'mac_address' },
    { header: 'ガス種', key: 'gas_specie' },
    { header: '電源', key: 'power_supply' },
    { header: '購入価格(￥)', key: 'purchased_price' },
    { header: '購入方法', key: 'purchased_method' },
    { header: '契約満了年月', key: 'contract_expiry_date' },
    { header: 'IDを使用する', key: 'current_use_id' },
    { header: '用途', key: 'current_use_name' },
    { header: 'ステータスID', key: 'current_status_id' },
    { header: '資産ステータス', key: 'current_status_name' },
    {
      header: '設置場所',
      key: 'current_installation_location',
    },
    { header: '使用場所', key: 'current_use_location' },
    {
      header: '会社ID',
      key: 'current_possession_company_id',
    },
    {
      header: '会社コード',
      key: 'current_possession_company_cd',
    },
    {
      header: '会社名',
      key: 'current_possession_company_name',
    },
    {
      header: '会社の略称',
      key: 'current_possession_company_short_name',
    },
    {
      header: '部門ID',
      key: 'current_possession_department_id',
    },
    {
      header: '部門コード',
      key: 'current_possession_department_cd',
    },
    {
      header: '部署名',
      key: 'current_possession_department_name',
    },
    {
      header: '従業員ID',
      key: 'current_possession_employee_id',
    },
    {
      header: '社員コード',
      key: 'current_possession_employee_cd',
    },
    {
      header: '社員名称',
      key: 'current_possession_employee_name',
    },
    { header: '保管場所ID', key: 'current_storage_place_id' },
    { header: '保管場所名', key: 'current_storage_place_name' },
    { header: '店舗コード', key: 'restaurant_cd'}
  ];

  return (
    <ExportLink
      target="_blank"
      filename={'template_asset.csv'}
      data={data}
      headers={headers}
      className="btn btn-primary csv-export"
    >
      <FaDownload /> CSVフォーマットDL
    </ExportLink>
  );
}
export default ExportCSV;
