import { FaUpload } from 'react-icons/fa';
import assetEditService from 'features/AssetScreen/pages/edit/assetService';
import './scss/components.scss';
import { AlertModalProps } from 'components/common/AlertModal';
import { GlobalModalContext } from 'components/common/GlobalModal';
import { MODAL_TYPES } from 'utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
interface ItemResponseCSV {
  detail: string;
  message: string;
}

function ImportCSV() {
  const history = useHistory();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const { t } = useTranslation();

  function handleShowAlertErrors(messages: string) {
    const data = JSON.parse(messages);
    let errors = '<div class="container align-items-center">';
    data.forEach((item: any) => {
      errors += '<div class="row">';
      errors += '<div class="col-sm">';
      errors += `${item.detail} ==> ${item.message}`;
      errors += '</div>';
      errors += '</div>';
    });
    errors += '</div>';
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('ERROR'),
      message: errors,
      size: 'lg',
    });
  }

  function handleShowErrors(message: string | undefined) {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('ERROR'),
      message: message ? message : '',
    });
  }

  function handleShowSuccess(message: string | undefined) {
    showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
      title: t('AssetScreen.edit.csv.title_success'),
      message: message ? message : '',
    });
  }

  async function handleImportCSV(event: any) {
    const file = event.target.files[0];
    const file_type = file.type;
    if (file_type !== 'text/csv') {
      alert('File Type required is csv file type');
      return false;
    }
    const formData = new FormData();
    formData.append('file', file);
    await assetEditService
      .importCSV(formData)
      .then((asset) => {
        const data: ItemResponseCSV[] = asset.data.errors;
        if (data.length > 0) {
          handleShowAlertErrors(JSON.stringify(data));
        } else {
          history.push('/asset');
          handleShowSuccess(t('AssetScreen.edit.csv.success'));
        }
      })
      .catch((err: AxiosError) => {
        handleShowErrors(err.response?.statusText.toString());
      });
  }
  return (
    <label className="custom-file-upload">
      <input
        type="file"
        className="btn btn-primary"
        onChange={handleImportCSV}
        onClick={(e: any) => (e.target.value = null)}
      />
      <FaUpload /> CSV一括変更
    </label>
  );
}
export default ImportCSV;
