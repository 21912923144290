import axios, { AxiosHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { authActions } from 'features/Auth/authSlice';
import authStorage from 'features/Auth/authStorage';
import { appActions } from 'features/common/appSlice';
import { StatusCodes } from 'http-status-codes';
import { store } from '../app/store';

const { dispatch } = store;

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    dispatch(appActions.showAppLoading());
    const token = authStorage.getAccessToken();
    config.headers = {
      ...config.headers,
      ...(token ? { Authorization: `Token ${token}` } : {}),
    } as AxiosHeaders;
    return config;
  },
  function (error) {
    dispatch(appActions.hideAppLoading());
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    dispatch(appActions.hideAppLoading());
    return response.data;
  },
  function (error) {
    dispatch(appActions.hideAppLoading());

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      if (error.config.url === '/logout') {
        dispatch(authActions.actionLogoutSuccess());
      } else {
        dispatch(authActions.actionLogout());
      }
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
