import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { CommonResponse } from "models";
import { EmployeeJob, Permission, EmployeeInfo } from "./models/login";
import { AxiosError } from "axios";
import authStorage from "./authStorage";
import { Company } from "features/common/models/company";

//auth state class
export interface UserState {
  loading: boolean;
  employee_info?: EmployeeInfo;
  selected_job?: EmployeeJob;
  permissions?: Permission;
  realtime_permissions?: Permission;
  company_list: Company[];
}

// init model state
const initialState: UserState = {
  loading: false,
  company_list: [],
};
// slice auth
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    actionLogout(state) {
      state.loading = true;
    },
    actionLogoutSuccess(state) {
      state.selected_job = undefined;
      state.permissions = undefined;
      state.employee_info = undefined;
      authStorage.removeTokens();
    },
    actionLogoutFailed(state, action: PayloadAction<AxiosError>) {
      if (action.payload.response && action.payload.response.status === 401) {
        state.selected_job = undefined;
        state.permissions = undefined;
        state.employee_info = undefined;
        authStorage.removeTokens();
      }
    },
    actionSetJob(state, action: PayloadAction<EmployeeJob>) {
      state.selected_job = action.payload;
    },
    actionSetEmployeeInfo(state, action: PayloadAction<EmployeeInfo>) {
      state.employee_info = action.payload;
    },
    fetchPermission(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setRealtimePermissions(state, action: PayloadAction<Permission>) {
      state.realtime_permissions = action.payload;
    },
    fetchPermissionSuccess(
      state,
      action: PayloadAction<CommonResponse<Permission>>,
    ) {
      state.permissions = action.payload.data;
    },
    fetchPermissionFailed(state) {
      state.loading = false;
    },
    fetchCompanyList(state) {
      state.loading = true;
    },
    fetchCompanySuccess(
      state,
      action: PayloadAction<CommonResponse<Company[]>>,
    ) {
      state.company_list = action.payload.data;
      state.loading = false;
    },
    fetchCompanyFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectPermission = (state: RootState) => state.auth.permissions;
export const selectRealtimePermission = (state: RootState) =>
  state.auth.realtime_permissions;

export const selectJobInfo = (state: RootState) => state.auth.selected_job;
export const selectLevel = (state: RootState) => state.auth.selected_job?.level;
export const selectEmployeeInfo = (state: RootState) =>
  state.auth.employee_info;
export const selectAuthCompanyList = (state: RootState) =>
  state.auth.company_list;

export const selectPermissionByFeature = (featureName: string) =>
  createSelector(selectPermission, (permission) =>
    permission ? permission[featureName] : [],
  );

export const selectAuthCompanyOptions = createSelector(
  selectAuthCompanyList,
  (companyList) => {
    return companyList.map((company: Company) => ({
      value: company.cd,
      label: company.name,
    }));
  },
);

// Reducer
export const authReducer = authSlice.reducer;
