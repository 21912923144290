import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AxiosError } from 'axios';
import { GlobalModalContext } from 'components/common/GlobalModal';
import {
  authActions,
  selectPermission,
  selectAuthCompanyOptions,
  selectAuthCompanyList,
} from 'features/Auth/authSlice';
import { companyActions } from 'features/common/companySlice';
import _ from 'lodash';
import { CommonResponse } from 'models';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, ModalProps, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import { STATUS_SUCCESS } from 'utils';
import { handleServiceError } from 'utils/common';
import authService from '../authService';
import authStorage from '../authStorage';
import { EmployeeJob, Login } from '../models/login';
import './LoginPage.scss';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LoginPage() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  // params
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  // selector
  const companiesOptions = useAppSelector(selectAuthCompanyOptions);
  const companiesList = useAppSelector(selectAuthCompanyList);
  const permissions = useAppSelector(selectPermission);
  // state
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [companySelected, setCompanySelected] = useState({
    value: '0',
    label: '',
  });
  // login as organization information
  const [preym, setPreym] = useState(0);
  const [jobList, setJobList] = useState<EmployeeJob[]>([]);
  const [selectedJobId, setSelectedJobId] = useState(Number);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(authActions.fetchCompanyList());
    dispatch(companyActions.fetchCompanyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCompanySelected({ value: '0', label: '' });
  }, [companiesList]);

  //submit form
  const submitForm = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      (async () => {
        try {
          let params = {
            company_id: companySelected.value,
            cd: event.target.username.value,
            password: event.target.password.value,
            preym: preym,
          } as Login;
          setIsLoading(true);
          const response = await authService.postLogin(params);
          if (response.data && response.status === STATUS_SUCCESS) {
            authStorage.setTokens(response.data.token);
            dispatch(authActions.actionSetEmployeeInfo(response.data.employee_info));
            setJobList(response.data.employee_jobs);
            if (response.data.employee_jobs && response.data.employee_jobs.length > 0) {
              setSelectedJobId(response.data.employee_jobs[0].employee_job_id);
              if (response.data.employee_jobs.length === 1) {
                handleCloseJobModal(response.data.employee_jobs[0].employee_job_id, response.data.employee_jobs);
              } else {
                setShowModal(true);
              }
            }
            setMessage('');
            setIsLoading(false);
          }
        } catch (e) {
          const axiosErr = e as AxiosError;
          if (axiosErr.response?.status === 422) {
            setMessage((axiosErr.response?.data as CommonResponse<null>).message);
          }
          handleServiceError(showGlobalModal, axiosErr);
          setIsLoading(false);
        }
      })();
    }
    event.currentTarget.blur();
    event.preventDefault();
  };

  /**
   *
   * @param evt
   * handle event when checked login with organization
   */
  function handleCheckedLoginOrganization(evt: any) {
    setPreym(evt.target.checked * 1);
  }

  /**
   * @param evt
   * handle event when comany changed
   */
  function handleChangeCompany(evt: any) {
    const company = companiesOptions.find((company) => company.value === evt.target.value && company.value !== '');
    if (company) {
      setCompanySelected(company);
    }
    message && setMessage('');
  }

  function handleCloseJobModal(jobId: number = -1, job_list: Array<EmployeeJob> = []) {
    let selected_job = null;
    if (job_list.length === 1) {
      selected_job = job_list.find((job) => job.employee_job_id === Number(jobId));
    } else {
      selected_job = jobList.find((job) => job.employee_job_id === Number(selectedJobId));
    }
    if (selected_job) {
      dispatch(authActions.actionSetJob(selected_job));
      dispatch(authActions.fetchPermission(`level=${selected_job.level}`));
      setShowModal(false);
    }
  }

  /**
   *
   * @param evt
   * handle event on change authenticator user login
   */
  function handleSelectJob(e: React.FormEvent<HTMLInputElement>) {
    if (e.currentTarget.checked) {
      let value = e.currentTarget.value;
      setSelectedJobId(Number(value));
    }
  }

  return _.size(permissions) > 0 ? (
    redirectUrl ? (
      <Redirect to={redirectUrl} />
    ) : (
      <Redirect to="/" />
    )
  ) : (
    <>
      <div className="container-login bg-main">
        <div className="card-login">
          <div className="text">
            <h3>{t('Login.title')}</h3>
          </div>
          <br />
          <Form noValidate validated={validated} onSubmit={submitForm}>
            <Form.Group className="mb-3">
              <div className="row">
                <Form.Label className="col-4 col-form-label text-end">{t('Login.company_name')}</Form.Label>
                <div className="col-7">
                  <Form.Select
                    onChange={handleChangeCompany}
                    required
                    defaultValue={companySelected && companySelected.value}
                  >
                    <option disabled value="0">
                      {t('Login.please_select_a_company')}
                    </option>
                    {companiesOptions.map((company, index) => {
                      return (
                        <option key={index} value={company.value}>
                          {company.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{t('Error.company')}</Form.Control.Feedback>
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row>
                <Form.Label className="col-4 col-form-label text-end">{t('Login.employee_code')}</Form.Label>
                <Col xs={7}>
                  <Form.Control
                    type="text"
                    name="username"
                    onChange={() => {
                      message && setMessage('');
                    }}
                    required
                  />
                  <Form.Control.Feedback type="invalid">{t('Error.employee_id')}</Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-3">
              <Row>
                <Form.Label className="col-4 col-form-label text-end">{t('Login.password')}</Form.Label>
                <Col xs={7}>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={() => {
                      message && setMessage('');
                    }}
                    autoComplete="true"
                    required
                  />
                  <Form.Control.Feedback type="invalid">{t('Error.password')}</Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicCheckbox">
              <Row>
                <Col className="offset-4">
                  <Form.Check
                    type="checkbox"
                    label={t('Login.login_with_previous_month_organization')}
                    onChange={handleCheckedLoginOrganization}
                  />
                </Col>
              </Row>
            </Form.Group>
            {message ? (
              <div className="error my-3">
                <span>{message}</span>
              </div>
            ) : (
              ''
            )}
            <div className="d-flex justify-content-center">
              <Button type="submit">
                {t('Login.sign_in')}
                {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <LoginWithOrganization
        show={showModal}
        handleSelectJob={handleSelectJob}
        jobList={jobList}
        handleCloseJobModal={handleCloseJobModal}
      />
    </>
  );
}

export default LoginPage;

interface ModalJobLogin extends ModalProps {
  handleSelectJob: (e: React.FormEvent<HTMLInputElement>) => void;
  jobList: Array<EmployeeJob>;
}

function LoginWithOrganization({ handleSelectJob, jobList, handleCloseJobModal, ...rest }: ModalJobLogin) {
  const { t } = useTranslation();
  return (
    <Modal {...rest} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="justify-content-center">
        <Modal.Title className="text-center">
          <div className="d-flex flex-column text-center">
            <div className="text">
              <h5>{t('Login.concurrent_post_proxy_authority_exists')} </h5>{' '}
            </div>
            <div className="text">
              <h5>{t('Login.please_select_a_job')}</h5>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <div className="row">
          <div className="col">
            <Form.Group controlId="formBasicRadio">
              {jobList?.map((item: EmployeeJob) => {
                return (
                  <Form.Check
                    key={item.employee_job_id}
                    id={item.employee_job_id.toString()}
                    type="radio"
                    label={`${item.company_name}  ${item.department_name} ${
                      item.job_id === null ? '-' : item.job_name
                    }`}
                    value={item.employee_job_id}
                    name="authenticator"
                    onChange={handleSelectJob}
                  />
                );
              })}
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button onClick={handleCloseJobModal}>{t('Login.selection')}</Button>
      </Modal.Footer>
    </Modal>
  );
}
