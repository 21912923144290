import { useAppSelector } from 'app/hooks';
import { AlertModalProps } from 'components/common/AlertModal';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';
import { GlobalModalContext } from 'components/common/GlobalModal';
import OwnershipChangeModal from 'components/common/OwnershipChangeModal';
import ExportCSV from 'features/AssetScreen/components/ExportCSV';
import ImportCSV from 'features/AssetScreen/components/ImportCSV';
import { Asset, AssetForm } from 'features/AssetScreen/models/Asset';
import { AssetModel } from 'features/AssetScreen/models/AssetModel';
import { DataLoadForm } from 'features/AssetScreen/models/DataLoadForm';
import { Manufacturer } from 'features/AssetScreen/models/Manufacturer';
import assetEditService from 'features/AssetScreen/pages/edit/assetService';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MODAL_TYPES } from 'utils';
import * as yup from 'yup';
import { selectManufacturers, selectModels } from '../assetEditSlice';
import '../scss/AssetEdit.scss';
import AssetHistory from './AssetHistory';

interface PropsDataLoadFormEdit {
  dataLoad: DataLoadForm;
  asset: Asset;
}
function AssetFormEdit({ dataLoad, asset }: PropsDataLoadFormEdit) {
  const { t } = useTranslation();
  const history = useHistory();
  const [purchasedDate, setPurchasedDate] = useState(moment(asset.purchased_date, 'YYYY-MM-DD').toDate());
  const [assetClassification, setAssetClassification] = useState('');
  const [applyDate, setApplyDate] = useState(new Date());
  const manufactures = useAppSelector(selectManufacturers);
  const [listManufatures, setListManufatures] = useState(manufactures.data);
  const models = useAppSelector(selectModels);
  const [listModels, setListModels] = useState(models.data);
  const [showOwnershipModal, setShowOwnershipModal] = useState(false);
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const [contractExpiryDate, setContractExpiryDate] = useState<undefined | Date>(
    asset.contract_expiry_date ? moment(asset.contract_expiry_date).toDate() : undefined
  );
  const [contractExpiryDateState, setcontractExpiryDateState] = useState(true);

  useEffect(() => {
    if (asset.purchased_method !== '2') {
      asset.contract_expiry_date = '';
      setContractExpiryDate(undefined);
      setcontractExpiryDateState(true);
    } else {
      setcontractExpiryDateState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (asset.list_manufactures.length > 0) {
      setListManufatures(asset.list_manufactures);
    }
    if (asset.list_models.length > 0) {
      setListModels(asset.list_models);
    }
  }, [asset]);

  function handleShowOwnershipChangeModal() {
    setShowOwnershipModal(true);
  }

  const schema = yup.object().shape({
    management_no: yup.string().required(t('AssetScreen.add.form.required_field')),
    purchased_date: yup.string().required(t('AssetScreen.add.form.required_field')),
    type: yup.number().required(t('AssetScreen.add.form.required_field')),
    model: yup.number().required(t('AssetScreen.add.form.required_field')),
    asset_classification_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    manufacturer_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    accounting_class_id: yup.number().required(t('AssetScreen.add.form.required_field')),
    purchased_price: yup
      .number()
      .required(t('AssetScreen.add.form.required_field'))
      .typeError(t('AssetScreen.add.form.number_field')),
    purchased_method: yup.string().required(t('AssetScreen.add.form.required_field')),
    current_status: yup.number().required(t('AssetScreen.add.form.required_field')),
    apply_date: yup.string().required(t('AssetScreen.add.form.required_field')),
  });

  const initial: AssetForm = {
    id: asset.id,
    management_no: asset.management_no,
    purchased_date: asset.purchased_date,
    size: asset.size ? asset.size : 0,
    asset_classification_id: asset.classification?.id,
    manufacturer_id: asset.manufacturer?.id,
    manufacturer_serial_number: asset.manufacturer_serial_number,
    external_order_number: asset.external_order_number,
    compressed_output: asset.compressed_output,
    accounting_class_id: asset.accounting_class?.id,
    accounting_no: asset.accounting_no ? asset.accounting_no : '',
    leasing_divided_contract_company: asset.leasing_divided_contract_company,
    type: asset.type.id,
    model: asset.model.id,
    model_number: asset.model_number ? asset.model_number : 0,
    mac_address: asset.mac_address ? asset.mac_address : '',
    gas_specie: asset.gas_specie ? asset.gas_specie : '',
    power_supply: asset.power_supply ? asset.power_supply : '',
    purchased_price: asset.purchased_price,
    purchased_method: asset.purchased_method,
    contract_expiry_date: asset.contract_expiry_date ? asset.contract_expiry_date : undefined,
    current_use: asset.current_use?.id,
    current_status: asset.current_status?.id,
    current_installation_location: asset.current_installation_location,
    current_use_location: asset.current_use_location,
    current_possession_company: {
      id: asset.current_possession_company?.id,
      short_name: asset.current_possession_company?.short_name,
      cd: asset.current_possession_company?.cd,
    },
    current_possession_department: {
      id: asset.current_possession_department?.id,
      name: asset.current_possession_department?.name,
      cd: asset.current_possession_department?.cd,
    },
    current_possession_employee: {
      id: asset.current_possession_employee?.id,
      cd: asset.current_possession_employee?.cd,
      name: asset.current_possession_employee?.username,
    },
    current_storage_place: {
      id: asset.current_storage_place?.id,
      name: asset.current_storage_place?.name,
      company: {
        id: asset.current_possession_company?.id,
        cd: asset.current_possession_company?.cd,
        short_name: asset.current_possession_company?.short_name,
      },
    },
    delete_flg: asset.delete_flg,
    updated_date: asset.updated_date,
    updated_employee: asset.updated_employee?.id,
    updated_company: asset.updated_company,
    apply_date: moment(applyDate).format('YYYY-MM-DD'),
    moving_day: moment(applyDate).format('YYYY-MM-DD'),
  };
  const [initialValues, setInitialValues] = useState(initial);
  const handleSubmitForm = async (values: AssetForm) => {
    if (values.purchased_method !== '2') {
      values.contract_expiry_date = '';
    }
    values.current_possession_company = initialValues.current_possession_company
      ? initialValues.current_possession_company.id
      : '';
    values.current_possession_department = initialValues.current_possession_department?.id;
    values.current_possession_employee = initialValues.current_possession_employee?.id;
    values.current_storage_place = initialValues.current_storage_place?.id;
    await assetEditService.saveAsset(values).then((asset) => {
      history.go(0);
    });
  };
  const handleChangeAssetClass = async (event: any) => {
    const query = `asset_classification_id=${event.target.value}`;
    setAssetClassification(event.target.value);
    if (event.target.value !== '') {
      await assetEditService
        .fetchManufactures(query)
        .then((result) => {
          setListManufatures(result.data);
        })
        .catch((err) => {
          const manufactures = err.response.data;
          setListManufatures(manufactures.data);
        });
    }
  };

  const handleChangeManufacture = async (event: any) => {
    const query = `asset_classification_id=${assetClassification}&manufacturer_id=${event.target.value}`;
    if (event.target.value !== '') {
      await assetEditService
        .fetchModels(query)
        .then((models) => {
          setListModels(models.data);
        })
        .catch((err) => {
          const models = err.response.data;
          setListModels(models.data);
        });
    }
  };

  const handleChangeManagementNo = debounce(
    async (event: any, setFieldError: (field: string, message: string | undefined) => void) => {
      const query = `management_no=${event.target.value}`;
      if (event.target.value !== '') {
        await assetEditService.checkAssetCode(query).catch((err) => {
          const asset = err.response.data;
          showGlobalModal<AlertModalProps>(MODAL_TYPES.ALERT_MODAL, {
            message: asset.message,
            title: t('App.error'),
          });
        });
      }
    },
    100
  );

  return (
    <div className="mb-3">
      <Container fluid="lg">
        {
          <Formik validationSchema={schema} onSubmit={handleSubmitForm} initialValues={initialValues}>
            {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
              return (
                <Form noValidate>
                  {/* 資産情報 */}
                  <ButtonGroup className="me-2">
                    <ImportCSV></ImportCSV>
                  </ButtonGroup>

                  <ButtonGroup className="me-2">
                    <ExportCSV></ExportCSV>
                  </ButtonGroup>
                  <Card className="mt-3">
                    <Card.Header as="h5">資産情報</Card.Header>
                    <Card.Body>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.management_no')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="management_no"
                            value={values.management_no}
                            onChange={async (event) => {
                              handleChange(event);
                              handleChangeManagementNo(event, setFieldError);
                            }}
                            isInvalid={!!errors.management_no}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">{errors.management_no}</Form.Control.Feedback>
                        </Col>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.type')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                            isInvalid={!!errors.type}
                          >
                            <option value="">---{t('AssetScreen.add.form.type')}---</option>
                            {dataLoad.data.asset_type.map((assetType, index) => {
                              return (
                                <option key={index} value={assetType.id}>
                                  {assetType.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.purchased_date')}
                        </Form.Label>
                        <Col xs sm="4">
                          <CustomDatePicker
                            value={purchasedDate}
                            onChange={(date: Date) => {
                              if (!date) {
                                date = new Date();
                              }
                              values.purchased_date = moment(date).format('YYYY-MM-DD');
                              setPurchasedDate(date);
                            }}
                            className="form-control"
                            name="purchased_date"
                            dateFormat="yyyy/MM/dd"
                            disabledKeyboardNavigation={true}
                          />
                          <Form.Control.Feedback type="invalid">{errors.purchased_date}</Form.Control.Feedback>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.asset_classification_id')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="asset_classification_id"
                            value={values.asset_classification_id}
                            onChange={(e) => {
                              handleChange(e);
                              handleChangeAssetClass(e);
                            }}
                            isInvalid={!!errors.asset_classification_id}
                          >
                            <option>
                              ---{t('AssetScreen.add.form.asset_classification_id')}
                              ---
                            </option>
                            {dataLoad.data.asset_classifications.map((assetClass, index) => {
                              return (
                                <option key={index} value={assetClass.id}>
                                  {assetClass.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.asset_classification_id}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.manufacturer_id')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="manufacturer_id"
                            value={values.manufacturer_id}
                            onChange={(e) => {
                              handleChange(e);
                              handleChangeManufacture(e);
                            }}
                            isInvalid={!!errors.manufacturer_id}
                          >
                            <option>---{t('AssetScreen.add.form.manufacturer_id')}---</option>
                            {listManufatures.map((manufacture: Manufacturer, index) => {
                              return (
                                <option key={manufacture.id} value={manufacture.id}>
                                  {manufacture.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.manufacturer_id}</Form.Control.Feedback>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.model')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="model"
                            value={values.model}
                            onChange={handleChange}
                            isInvalid={!!errors.model}
                          >
                            <option>---{t('AssetScreen.add.form.model')}---</option>
                            {listModels.map((model: AssetModel) => {
                              return (
                                <option key={model.id} value={model.id}>
                                  {model.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.model}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.size')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="size"
                            value={values.size}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.model_number')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="model_number"
                            value={values.model_number}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.manufacturer_serial_number')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="manufacturer_serial_number"
                            value={values.manufacturer_serial_number}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.mac_address')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="mac_address"
                            value={values.mac_address}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.external_order_number')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="external_order_number"
                            value={values.external_order_number}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.gas_specie')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="gas_specie"
                            value={values.gas_specie}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.compressed_output')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="compressed_output"
                            value={values.compressed_output}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.power_supply')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="power_supply"
                            value={values.power_supply}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.accounting_class_id')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="accounting_class_id"
                            value={values.accounting_class_id}
                            onChange={handleChange}
                            isInvalid={!!errors.accounting_class_id}
                          >
                            <option>---{t('AssetScreen.add.form.accounting_class_id')}---</option>
                            {dataLoad.data.accounting_class.map((accountingClass, index) => {
                              return (
                                <option key={index} value={accountingClass.id}>
                                  {accountingClass.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.accounting_class_id}</Form.Control.Feedback>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.purchased_price')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="number"
                            name="purchased_price"
                            value={parseInt(values.purchased_price.toString())}
                            onChange={handleChange}
                            isInvalid={!!errors.purchased_price}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">{errors.purchased_price}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.accounting_no')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="accounting_no"
                            value={values.accounting_no}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.purchased_method')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="purchased_method"
                            value={values.purchased_method}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                              handleChange(event);
                              const purchased_method = event.target.value;
                              if (purchased_method === '2') {
                                setcontractExpiryDateState(false);
                              } else {
                                setcontractExpiryDateState(true);
                                setContractExpiryDate(undefined);
                              }
                            }}
                            isInvalid={!!errors.purchased_method}
                          >
                            <option>---{t('AssetScreen.add.form.purchased_method')}---</option>
                            {dataLoad.data.purchased_method.map((purchasedMethod, index) => {
                              return (
                                <option key={index} value={purchasedMethod.id}>
                                  {purchasedMethod.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.purchased_method}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.leasing_divided_contract_company')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="leasing_divided_contract_company"
                            value={values.leasing_divided_contract_company}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.contract_expiry_date')}
                        </Form.Label>
                        <Col xs sm="4">
                          <CustomDatePicker
                            disabled={contractExpiryDateState}
                            value={contractExpiryDate}
                            onChange={(date: Date) => {
                              if (values.purchased_method === '2') {
                                if (!date) {
                                  date = new Date();
                                }
                                values.contract_expiry_date = moment(date).format('YYYY-MM-DD');
                                setContractExpiryDate(date);
                                setcontractExpiryDateState(false);
                              }
                            }}
                            disabledKeyboardNavigation={true}
                            className="form-control"
                            name="contract_expiry_date"
                            dateFormat="yyyy/MM/dd"
                          />
                          <Form.Control.Feedback type="invalid">{errors.contract_expiry_date}</Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  {/* 資産ステータス情報 */}
                  <Card className="mt-3">
                    <Card.Header as="h5">資産ステータス情報</Card.Header>
                    <Card.Body>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_use')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="current_use"
                            value={values.current_use}
                            onChange={handleChange}
                            isInvalid={!!errors.current_use}
                          >
                            <option>---{t('AssetScreen.add.form.current_use')}---</option>
                            {dataLoad.data.asset_uses.map((assetUse, index) => {
                              return (
                                <option key={index} value={assetUse.id}>
                                  {assetUse.name}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">{errors.current_use}</Form.Control.Feedback>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          <span className="text-danger">※</span>
                          {t('AssetScreen.add.form.current_status')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Select
                            size="sm"
                            name="current_status"
                            value={values.current_status}
                            onChange={handleChange}
                            isInvalid={!!errors.current_status}
                          >
                            <option>---{t('AssetScreen.add.form.current_status')}---</option>
                            {dataLoad.data.asset_status.map((assetStatus, index) => {
                              return (
                                <option key={index} value={assetStatus.id}>
                                  {assetStatus.name}
                                </option>
                              );
                            })}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">{errors.current_status}</Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_installation_location')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="current_installation_location"
                            value={values.current_installation_location}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_use_location')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Control
                            className="form-control-sm"
                            type="text"
                            name="current_use_location"
                            value={values.current_use_location}
                            onChange={handleChange}
                          ></Form.Control>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  {/* 資産保有情報 */}
                  <Card className="mt-3">
                    <Card.Header as="h5">
                      <Row>
                        <Col xs sm="2">
                          <Form.Label className="col-form-label text-end">資産保有情報</Form.Label>
                        </Col>
                        <Col xs sm="2">
                          <Button onClick={handleShowOwnershipChangeModal}>移動処理</Button>
                          <OwnershipChangeModal
                            show={showOwnershipModal}
                            setShowOwnershipModal={setShowOwnershipModal}
                            setInitialValues={setInitialValues}
                            initialValues={values}
                            companies={dataLoad.data.companies}
                            storage_places={dataLoad.data.storage_places}
                            from_company={
                              values.current_possession_company?.id
                                ? `${values.current_possession_company?.cd} ${values.current_possession_company?.short_name}`
                                : ''
                            }
                            from_department={
                              values.current_possession_department?.id
                                ? `${values.current_possession_department?.cd} ${values.current_possession_department?.name}`
                                : ''
                            }
                            from_storage_place={
                              values.current_storage_place?.id ? `${values.current_storage_place?.name}` : ''
                            }
                            from_employee={
                              values.current_possession_employee?.id
                                ? `${values.current_possession_employee?.name}`
                                : ''
                            }
                          />
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_possession_company')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Label className="col-sm-6 col-form-label text-end">
                            {initialValues.current_possession_company?.cd}{' '}
                            {initialValues.current_possession_company?.short_name}
                          </Form.Label>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_possession_department')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Label className="col-sm-6 col-form-label text-end">
                            {initialValues.current_possession_department?.cd}{' '}
                            {initialValues.current_possession_department?.name}
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_storage_place')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Label className="col-sm-4 col-form-label text-end">
                            {initialValues.current_storage_place?.name}
                          </Form.Label>
                        </Col>

                        <Form.Label className="col-sm-2 col-form-label text-end">
                          {t('AssetScreen.add.form.current_possession_employee')}
                        </Form.Label>
                        <Col xs sm="4">
                          <Form.Label className="col-sm-4 col-form-label text-end">
                            {initialValues.current_possession_employee?.name}
                          </Form.Label>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Row className="align-items-center mt-3 mb-3">
                    <Col xs sm="3"></Col>
                    <Col xs sm="4">
                      <div className="inline">
                        <span className="text-danger">※</span>
                        <span>{t('AssetScreen.add.form.apply_date')}</span>
                        <CustomDatePicker
                          value={applyDate}
                          onChange={(date: Date) => {
                            if (!date) {
                              date = new Date();
                            }
                            values.apply_date = moment(date).format('YYYY-MM-DD');
                            setApplyDate(date);
                          }}
                          disabledKeyboardNavigation={true}
                          className="form-control w-75"
                          name="apply_date"
                          dateFormat="yyyy/MM/dd"
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">{errors.apply_date}</Form.Control.Feedback>
                    </Col>
                    <Col xs sm="1">
                      <Button
                        onClick={() => {
                          setFieldValue('delete_flg', false);
                          handleSubmit();
                        }}
                      >
                        {t('AssetScreen.add.form.button_ok')}
                      </Button>
                    </Col>
                    <Col xs sm="1">
                      <Button
                        onClick={() => {
                          setFieldValue('delete_flg', true);
                          handleSubmit();
                        }}
                        className="btn--red"
                      >
                        {t('AssetScreen.add.form.button_submit_del')}
                      </Button>
                    </Col>

                    <Col xs sm="3"></Col>
                  </Row>

                  {/* 変更履歴 */}
                  <AssetHistory asset={asset} />
                </Form>
              );
            }}
          </Formik>
        }
      </Container>
    </div>
  );
}

export default AssetFormEdit;
